import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

export default (props)=> {
  const imageQuery = useStaticQuery(graphql`
  query{
    file(relativePath: {eq: "img/avatar.png"}) {
      childImageSharp{
        original{
          src
        }
      }
    }
  }
`);

const avatarImage = imageQuery.file.childImageSharp.original.src;

    return (
        <section className="mx-auto  mt-16">
       
         <div className="leading-relaxed tracking-wide ">

        <div className="px-6 py-4">
        <h2 className="md:p-3 md:mr-32 md:-ml-12 p-2 text-xl border-b-4 font-semibold border-blue-600 text-blue-600 p-2 mb-4">About me</h2>  
        
        <div className="img-about -ml-2 float-right md:h-24 ml-2 ">
        <img src={avatarImage} alt="jfloat avascript avatar geekynut" className="ml-4 invisible md:visible h-0 w-0 md:h-24 md:w-24 md:shadow rounded-full"/>
          </div>        
        <p className="text-gray-800  md:mt-12 text-justify">
          Hi all, I am Simon, I work in IT. At the moment Web development is not my full time mansion but I am working to change it ;) I started coding about 10 years ago but things got serious only 4 years ago when I fell in love for javascript. I am an eternal student and love to study different languages and frameworks.
           I decided to create geekynuts so that I could share a little bit of my knowdlege hoping it can help someone out there.
           I am planning to create a multi topic blog, so if you have your geeky idea contact me  
                   
           </p>
      </div>
                
      
      
      </ div>
      <div className="text-right">
      
      </div>

      </section>
    )
}